import type { SearchIndexUid, SearchResponseResult } from "~/types/api/Search";

export function getHitsFromSearchResponseByQueryType<T>(
  queryType: SearchIndexUid,
  results: SearchResponseResult | undefined,
): T[] {
  if (results === undefined) {
    return [];
  }

  const queryTypeResults = results.find((result) => result.indexUid === queryType);

  return queryTypeResults ? (queryTypeResults.hits as T[]) : [];
}
