import { computed, ref, watchEffect } from "vue";
import { useSchedulerStore } from "@/store/scheduler";
import type { Slot } from "~/types/Slot";

export function useSchedulerServiceSlots(serviceId: number) {
  const schedulerStore = useSchedulerStore();
  const slots = ref<Slot[]>([]);
  const pending = ref(true);
  const failed = ref(false);

  watchEffect(() => {
    slots.value = [];
    pending.value = true;
    failed.value = false;

    schedulerStore
      .getSlots({ service: serviceId })
      .then((data) => {
        if (data !== null) {
          slots.value = data.value.slots;
        } else {
          failed.value = true;
        }
      })
      .finally(() => {
        pending.value = false;
      });
  });

  const nextFreeSlot = computed(() => {
    if (slots.value.length > 0) {
      return slots.value[0];
    }

    return null;
  });

  return {
    failed,
    nextFreeSlot,
    pending,
    slots,
  };
}
