<template>
  <div
    class="circle max-w-screen-xl bg-right bg-no-repeat pb-8 md:mx-6 md:mt-6 lg:mx-auto lg:flex lg:flex-row lg:gap-8 lg:bg-[url('assets/svg/circleIllustration.svg')] lg:px-5">
    <Teleport to="body"><SharedSearchModal :departments="departments" /></Teleport>
    <div
      :style="imageStyle"
      class="backgroundImage flex h-fillerImg w-auto flex-col-reverse bg-cover bg-[center_40%] bg-no-repeat md:rounded-3xl md:bg-[center_35%] lg:h-[589px] lg:w-full lg:bg-center">
      <div class="mb-6 p-5 text-white lg:pb-0">
        <h2 class="mb-2 text-2xl leading-tight lg:text-3xl">{{ data.headline }}</h2>
        <ul class="list-disc pl-5 text-sm lg:text-base">
          <li v-for="(advantage, key) in data.advantages" :key="key" class="marker:text-yellow">
            {{ advantage.text }}
          </li>
        </ul>
      </div>
    </div>
    <section
      class="relative -mt-6 flex w-full flex-col overflow-hidden rounded-2xl shadow-md md:mt-6 lg:mt-0 lg:h-[589px] lg:rounded-3xl">
      <div class="flex justify-center gap-6 bg-brick-200 px-6 py-4 md:justify-normal">
        <ul class="flex gap-6">
          <li v-for="(rating, key) in data.ratings.data" :key="`rating-${key}`">
            <SharedRating :rating="rating.attributes" class="text-xs md:text-base" />
          </li>
        </ul>
      </div>
      <div class="flex min-h-0 flex-grow flex-col bg-white px-6">
        <h2 class="mt-2 py-2 text-2xl font-normal lg:text-4xl">Buche deinen Termin</h2>
        <div class="flex min-h-0 cursor-text flex-col">
          <SharedSearch :departments="departments" />
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
import type { SearchHero } from "~/types/strapi/components/block/interfaces/SearchHero";

const img = useImage();

const props = defineProps<{
  data: SearchHero;
}>();

const departments = computed(() => {
  if (props.data.departments.data) {
    return props.data.departments.data;
  }

  return [];
});

const backgroundImageSrc = computed(() => {
  return img(
    props.data.leftImage.data.attributes.url,
    {
      breakpoint: "large",
    },
    {
      provider: "strapiHaelsi",
    },
  );
});

const imageStyle = reactive({
  backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.54) 60%, rgba(0, 0, 0, 0.72) 100%), url('${backgroundImageSrc.value}')`,
});
</script>

<style scoped></style>
