interface HealthProfessionalAvatar {
  alternativeText: string | null;
  formats: {
    thumbnail: {
      url: string;
    };
  };
}

export interface BaseHealthProfessionalHit {
  firstName: string;
  lastName: string;
  prefix: string;
  suffix: string | null;
  slug: string;
  bookingID: string;
  services: { id: number; name: string; serviceId: number }[];
  avatar: HealthProfessionalAvatar;
  departments: { title: string }[];
}

interface BaseServiceHit {
  name: string;
  serviceId: number;
  uuid: number;
  healthProfessionals: { id: string }[];
  department: { id: number; title: string; slug: string };
}

interface BaseDepartmentHit {
  title: string;
  id: number;
  slug: string;
  services: { id: number; name: string; serviceId: number }[];
}

export interface HealthProfessionalHit extends BaseHealthProfessionalHit {
  _formatted: BaseHealthProfessionalHit;
}

export interface ServiceHit extends BaseServiceHit {
  _formatted: BaseServiceHit;
}

export interface DepartmentHit extends BaseDepartmentHit {
  _formatted: BaseDepartmentHit;
}

type Hit = HealthProfessionalHit | ServiceHit | DepartmentHit;

export type SearchIndexUid = "health-professional" | "department" | "service";

export const HIGHLIGHT_PRE_TAG = "<span class='text-gray-900 font-medium'>";
export const HIGHLIGHT_POST_TAG = "</span>";

interface BaseQuery {
  indexUid: SearchIndexUid;
  q: string;
  attributesToHighlight: string[];
  highlightPreTag: typeof HIGHLIGHT_PRE_TAG;
  highlightPostTag: typeof HIGHLIGHT_POST_TAG;
}

interface DepartmentQuery extends BaseQuery {
  indexUid: "department";
  attributesToHighlight: ["title"];
}

interface HealthProfessionalQuery extends BaseQuery {
  indexUid: "health-professional";
  attributesToHighlight: ["firstName", "lastName"];
}

interface ServiceQuery extends BaseQuery {
  indexUid: "service";
  attributesToHighlight: ["name"];
}

export interface SearchRequest {
  queries: [DepartmentQuery, HealthProfessionalQuery, ServiceQuery];
}

interface BaseApiSearchResult {
  indexUid: SearchIndexUid;
  hits: Hit[];
  limit: number;
  offset: number;
  processingTimeMs: number;
  query: string;
  estimatedTotalHits?: number;
}

export interface HealthProfessionalSearchResult extends BaseApiSearchResult {
  indexUid: "health-professional";
  hits: HealthProfessionalHit[];
}

export interface DepartmentSearchResult extends BaseApiSearchResult {
  indexUid: "department";
  hits: DepartmentHit[];
}

export interface ServiceSearchResult extends BaseApiSearchResult {
  indexUid: "service";
  hits: ServiceHit[];
}

export type SearchResponseResult = [DepartmentSearchResult, HealthProfessionalSearchResult, ServiceSearchResult];

export interface SearchResponse {
  results: SearchResponseResult;
}
