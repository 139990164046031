<template>
  <div class="mt-2 flex min-h-0 flex-col">
    <div class="relative flex min-h-0 w-full flex-col">
      <div>
        <MagnifyingGlassIcon
          class="absolute inset-y-0 left-0 top-0 z-10 ml-2 mt-2.5 flex items-center text-3xl text-gray-500" />
        <button
          v-if="search !== ''"
          class="absolute right-0 top-0 z-10 mr-2 mt-2.5 flex items-center rounded-full bg-gray-100 p-1.5 text-lg font-bold text-gray-700"
          aria-label="Text löschen"
          @click="clearInput">
          <XMarkIcon />
        </button>
        <input
          id="haelsi-search"
          ref="input"
          v-model="search"
          type="text"
          autocomplete="off"
          placeholder="Fachrichtung, Ärzt:in oder Terminart"
          class="left-0 top-0 w-full rounded-lg border-gray-900 p-3 pl-11 pr-10 text-lg placeholder:text-base focus:border-yellow focus:ring-yellow"
          @focusin="handleClickOnSearch"
          @input="onInput" />
      </div>
      <UiSpinner v-if="pending" class="my-4 min-h-min" :expand="false" />
      <template v-else>
        <div class="flex min-h-0 flex-col lg:overflow-y-auto">
          <ul v-if="search !== ''" class="mt-2 [&>*:last-child]:border-b-0">
            <li v-for="department in filterDepartments" :key="department.id" class="border-b-2 border-gray-100">
              <SearchDepartmentCardResult :department="department" data-track-card-department="true" />
            </li>
            <li
              v-for="healthProfessional in results.healthProfessional"
              :key="healthProfessional.bookingID"
              class="w-full border-b-2 border-gray-100">
              <SearchDoctorCardResult
                :health-professional="healthProfessional"
                data-track-card-health-professional="true" />
            </li>
            <li v-for="service in results.service" :key="service.serviceId" class="w-full border-b-2 border-gray-100">
              <SearchServiceCardResult :service="service" data-track-card-service="true" />
            </li>
          </ul>
        </div>
        <template v-if="search === ''">
          <ul class="mt-2 [&>*:last-child]:border-b-0">
            <li v-for="department in departments" :key="department.id" class="border-b-2 border-gray-100">
              <SharedSearchStaticCard :department="department" data-track-card-static="true" />
            </li>
          </ul>
          <SearchMoreDepartmentsCard class="mb-3 mt-3" data-track-card-more-departments="true" />
        </template>
        <div v-if="noResult" class="mt-4 flex flex-col rounded-lg bg-brick-100 p-6">
          <PersonAskingIllustration class="mx-auto" />
          <p>Dazu können wir leider nichts finden.</p>
          <p class="text-gray-600">
            Versuche es mit einer anderen Schreibweise. Bei Fragen helfen wir dir gerne weiter.
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { breakpointsTailwind } from "@vueuse/core";
import { debounce } from "perfect-debounce";
import { ref } from "vue";
import MagnifyingGlassIcon from "~/assets/svg/icons/magnifying-glass.svg";
import XMarkIcon from "~/assets/svg/icons/xMark.svg";
import PersonAskingIllustration from "~/assets/svg/personAskingIllustration.svg?skipsvgo";
import SearchDepartmentCardResult from "~/components/SearchDepartmentCardResult.vue";
import SearchDoctorCardResult from "~/components/SearchDoctorCardResult.vue";
import SearchMoreDepartmentsCard from "~/components/SearchMoreDepartmentsCard.vue";
import SearchServiceCardResult from "~/components/SearchServiceCardResult.vue";
import { useCancelRequestStore } from "~/store/cancelRequest";
import { useSearchModalStore } from "~/store/searchModal";
import type { SearchResult } from "~/types/SearchResult";

const props = withDefaults(
  defineProps<{
    departments?: any[];
    onModal?: boolean;
  }>(),
  {
    departments: () => [],
    onModal: false,
  },
);

const results = ref<any>({});
const search = ref("");
const noResult = computed(
  () =>
    filterDepartments.value.length + results.value.healthProfessional?.length + results.value.service?.length === 0 &&
    search.value !== "",
);

const breakpoints = breakpointsTailwind;
const mobileMaxWidth = breakpoints.lg;
const store = useSearchModalStore();
const input = ref<HTMLInputElement | null>(null);
const pending = ref(false);

onMounted(() => {
  if (props.onModal) {
    input.value?.focus();
  }
});
const handleClickOnSearch = () => {
  if (window.innerWidth > mobileMaxWidth) {
    return;
  }

  if (!store.isVisible) {
    store.toggleModal();
  }
};

const clearInput = () => {
  search.value = "";
  input.value?.focus();
};

const filterDepartments = computed(() => {
  const hasDepartments = results.value && results.value.department;
  if (!hasDepartments) {
    return [];
  }
  return results.value.department.filter((d: any) => d.services && d.services.length > 0);
});

const performSearch = debounce(async () => {
  try {
    const searchValue = search.value;
    results.value = await useSearchApiFetch(searchValue);
    trackResult(searchValue, results.value);
  } catch (error) {
    console.log("Could not get search results. Error: ", error);
  } finally {
    pending.value = false;
  }
}, 400);

const onInput = () => {
  if (search.value !== "") {
    pending.value = true;
    useCancelRequestStore().cancel();
    performSearch();
  }
};

async function trackResult(searchValue: string, result: SearchResult | undefined) {
  if (result === undefined) {
    return;
  }

  const { department, healthProfessional, service } = result;
  const amount = healthProfessional.length + service.length + department.length;

  sendMatomoEvent({
    category: false,
    keyword: searchValue.trim().toLowerCase(),
    resultsCount: amount,
    type: MatomoEventType.trackSiteSearch,
  });
}
</script>

<style scoped></style>
