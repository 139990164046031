<template>
  <div>
    <NuxtLink class="flex w-full justify-between py-2" :to="`/${department.slug}`">
      <div class="mt-1 flex overflow-hidden">
        <StethoscopeIcon />
      </div>
      <div class="ml-2 flex w-full flex-col self-center">
        <div :class="{ 'flex items-center gap-x-2': isPromoted }">
          <span v-if="isPromoted" class="rounded-lg bg-yellow p-3 py-0.5 text-sm font-medium text-gray-900">Neu</span>
          <h3 class="my-auto text-gray-800" v-html="department._formatted.title" />
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import StethoscopeIcon from "assets/svg/icons/stethoscope.svg?skipsvgo";
import type { DepartmentHit } from "~/types/api/Search";

const props = defineProps<{
  department: DepartmentHit;
}>();

const isPromoted = isPromotedDepartment(props.department.title);
</script>

<style scoped></style>
