<template>
  <div>
    <NuxtLink v-if="serviceLink" class="flex w-full justify-between py-2" :to="serviceLink">
      <div class="avatar mt-1 flex h-8 w-8 overflow-hidden rounded-full">
        <CalendarIcon />
      </div>
      <div class="ml-2 flex w-full flex-col leading-snug">
        <h3 class="my-auto text-gray-800" v-html="appointmentName" />
        <p v-if="service.department" class="text-sm text-gray-900">{{ service.department.title }}</p>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
import slugify from "@sindresorhus/slugify";
import CalendarIcon from "assets/svg/icons/calendarDate.svg?skipsvgo";
import { computed } from "vue";
import type { ServiceHit } from "~/types/api/Search";
import { isPreventiveCheckupServiceByName } from "~/utils/preventiveCare/isPreventiveCheckupService";

const props = defineProps<{
  service: ServiceHit;
}>();

const serviceLink = computed(() => {
  if (!props.service.department) {
    return "";
  }

  if (isPreventiveCheckupServiceByName(props.service.department.title)) {
    return `/${props.service.department.slug}`;
  }

  return `/${props.service.department.slug}/${slugify(props.service.name)}-${props.service.uuid}`;
});

const appointmentName = computed(() => getAppointmentNameFromService(props.service._formatted.name));
</script>

<style scoped></style>
