import type { GroupOrService } from "~/types/GroupOrService";
import { isPrimaryService } from "~/utils/isPrimaryService";

/**
 * This function is copied to
 * `backend/src/api/service/util/getFirstAvailableOrdinationIdFromServices.ts`
 * If you edit this, please also adapt the other file to reflect the changes
 */
export const getFirstAvailableOrdinationIdFromServices = (groupOrService: GroupOrService[]): number[] => {
  const ordination = groupOrService.find((service) => isPrimaryService(service.name));
  const firstService = ordination ?? groupOrService[0];

  if (firstService.serviceId) {
    return [firstService.serviceId];
  } else if (firstService.services) {
    return firstService.services;
  }

  return [];
};
