<template>
  <div>
    <div
      v-if="store.isVisible"
      tabindex="-1"
      aria-hidden="true"
      class="fixed left-0 right-0 top-0 z-50 flex h-full max-h-full w-full items-center justify-center overflow-y-auto overflow-x-hidden bg-gray-900 bg-opacity-30 md:inset-0 lg:hidden">
      <div class="relative h-full w-full bg-white">
        <!-- Modal content -->
        <div class="relative bg-white p-2 px-5 md:px-20 md:pt-4">
          <!-- Modal header -->
          <div class="flex justify-between md:text-5xl">
            <h1 class="text-2xl">Buche deinen Termin</h1>
            <button
              type="button"
              class="ml-auto inline-flex items-center rounded-lg bg-transparent text-gray-900 hover:bg-gray-100"
              @click="store.toggleModal()">
              <XMarkIcon class="text-3xl" />
              <span class="sr-only">Schließen</span>
            </button>
          </div>
          <SharedSearch :on-modal="true" :departments="props.departments" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import XMarkIcon from "~/assets/svg/icons/xMark.svg";
import { useSearchModalStore } from "~/store/searchModal";
const store = useSearchModalStore();

const props = withDefaults(
  defineProps<{
    departments?: any[];
  }>(),
  {
    departments: () => [],
  },
);
</script>

<style scoped></style>
