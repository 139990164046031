import { defineStore } from "pinia";

export const useSearchModalStore = defineStore("searchModalStore", () => {
  const isVisible = ref(false);
  function toggleModal() {
    isVisible.value = !isVisible.value;
  }

  return { isVisible, toggleModal };
});
